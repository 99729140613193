<template>
    <div>

      <v-layout wrap justify-center>
        <v-flex lg11>

         

      <v-layout wrap justify-center>
        <v-flex lg12 px-5 pt-10>
              <span style="font-family: Montserrat-Bold; font-size: 28px;">Contact Us</span>
            </v-flex>
      </v-layout>

      <v-container>
        <v-row>
          <v-col>
            <v-simple-table>
              <thead>
                <tr>
                  <th style="font-size: 18px; font-weight: bold;">Name</th>
                  <th style="font-size: 18px; font-weight: bold;">Date</th>
                  <th style="font-size: 18px; font-weight: bold;">Email</th>
                  <th style="font-size: 18px; font-weight: bold;">Message</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(message, index) in messageList" 
                  :key="index" 
                  @click="openMessageDialog(message)"
                  style="cursor: pointer;"
                >
                  <td>{{ message.name }}</td>
                  <td>{{ formatDate(message.create_date) }}</td>
                  <td>{{ message.email }}</td>
                  <td>{{ trimMessage(message.message) }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
  
      <!-- Message Detailed View Dialog -->
      <v-dialog v-model="messageDetailedViewDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Message Details</span>
          </v-card-title>
          <v-card-text>
            <p><strong>Name:</strong> {{ selectedMessage.name }}</p>
            <p><strong>Email:</strong> {{ selectedMessage.email }}</p>
            <p><strong>Date:</strong> {{ formatDate(selectedMessage.create_date) }}</p>
            <p><strong>Time:</strong> {{ formatTime(selectedMessage.create_date) }}</p>
            <p><strong>Message:</strong></p>
            <p>{{ selectedMessage.message }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="messageDetailedViewDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-flex>
  </v-layout> 
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        messageList: [],
        messageDetailedViewDialog: false,
        selectedMessage: {},
      };
    },
    mounted() {
      this.getMessageList();
    },
    methods: {

      formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
    },

    // formatTime(date) {
    //   const d = new Date(date);
    //   const hours = String(d.getHours()).padStart(2, '0');
    //   const minutes = String(d.getMinutes()).padStart(2, '0');
    //   return `${hours}:${minutes}`; // Return `HH:MM:SS`
    // },

    formatTime(date) {
      const d = new Date(date);
      let hours = d.getHours();
      const minutes = String(d.getMinutes()).padStart(2, '0');
      const period = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12; // Convert to 12-hour format, ensuring 12 is shown instead of 0
      return `${String(hours).padStart(2, '0')}:${minutes} ${period}`;
    },


      trimMessage(message) {
        if (!message) return ""; // Handle null or undefined messages
        return message.length > 40 ? message.substring(0, 40) + "..." : message;
      },
      getMessageList() {
        this.appLoading = true;
        axios({
          url: "/message/list",
          method: "GET",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.messageList = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      openMessageDialog(message) {
        this.selectedMessage = message;
        this.messageDetailedViewDialog = true;
      },
      navigateToSubPlans() {
        this.$router.push({ path: '/subcategory' });
      },
      navigateToRegions() {
        this.$router.push({ path: '/banner' });
      },
      navigateToCountries() {
        this.$router.push({ path: '/Country' });
      },
      navigateToEmail() {
        this.$router.push({ path: '/userList', query: { method: 'Email' } });
      },
      navigateToApple() {
        this.$router.push({ path: '/userList', query: { method: 'Apple' } });
      },
      navigateToGmail() {
        this.$router.push({ path: '/userList', query: { method: 'Google' } });
      },
    },
  };
  </script>
  